
.Contacts {
    display: flex;
    width: 100%;
    height: 100vh;
}

.splitscreen {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100vh;
}

.searchbar {
    display: flex;
    border-bottom: 1px solid lightgray;
}

#image {
    width: 40px;
    height: 40px;
    border-radius: 35px;
    margin-top:15px;
}

h2#heading {
    /* display: flex; */
    position: absolute;
    margin-right: 25%;
    margin-left: 2%;
    color: #6e6969;
    font-style: normal;
    font-weight: 600;
    font-size:1.5rem;
    /* line-height: 49px; */
}

p#admin {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0px;
    margin-top: 15px;
}

p#user {
    margin-top: 0px;
    margin-right: 10px;
    font-size: x-small;
}

input#search {
    width: 21pc;
    height: 2.2rem;
    margin: auto;
    margin-right: 7%;
    border-radius: 10px;
    padding: 0px 10px;
    border: 1px solid lightgray;
    background-color: #ededed;
}
hr {
    border: 1px solid #EAEAEA;
    background-color: #EAEAEA;
}

/* table */
.table {
   
    width: 97%;
    height: 94%;
    /* 
    background-color: white;
    padding: 20px 0px 0px 0px; */
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin: auto;
    display: block;
    height:70vh;
    overflow-y: auto;
    border-radius: 10px;
    font-style: normal;
    /* font-weight:bold; */
    font-size: 0.9rem;
    line-height: 30px;
    
}

.tabel::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.table::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
    
  
/* 
table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    
} */

.checkbox{
    box-sizing: border-box;
    
        position: relative;
        width: 20px;
        height: 20px;
        
        top: 2px;
        left: 0.5rem;
        background: #FBF8F8;
        
        border: 0.5px solid #2DA5FC;
        border-radius: 3px;
}
thead {
    background: #B3DFFF;
        box-shadow: inset -1px -1px 20px rgba(254, 134, 39, 0.1), inset 1px 1px 20px rgba(254, 134, 39, 0.1);
        border-radius: 10px;
        height: 8vh;
        position: sticky;
        top: 0%;
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 30px;
}

thead>* {
    width: 16%;
    text-align: center;
}

tbody {
    background-color: white;
}

tr {
    
        height: 8vh
}

tr:nth-child(even) {
    background-color: rgba(178, 223, 255, 0.31);
    border: 1px solid rgba(178, 223, 255, 0.31) ;
        border-radius: 15px;
}

.name{
    width: 16%;
    text-align: start;
    padding-left: 2rem;
    
}

.designation, .company, .industry,
.email{
    width: 14%;
    text-align: start;
    /* padding-left: 1rem; */
}

.phoneNumber {
    width: 16%;
    text-align: start;
}
tbody .phoneNumber{
    letter-spacing: 0.02rem;
}

.country{
    width: 12%;
    text-align: start;
}
.action{
    width: 8%;
}
/* Aside bar */


/* p {
    font-size: small;
} */

p#logout {
    position: relative;
    top: 72.2vh;
}

/* Tooltip */
.tooltip{
    background-color: white;
;
}

.email:hover .tooltip{
    display: block;
    visibility: visible;
    position: absolute;
    width: 250px;
    height: 43px;
    left: 80%;
    top: 748px;
    background: #FFFFFF;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}



/* Buttons */
.buttons {
    display: flex;
    width: 100%;
    margin-top: 10px;
    height:11%
}

button {
    border: 1px solid lightgray;
    background-color: white;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
}

/* Modal */
.aside {
    width: 15%;
    height: 100vh;
    background-color: #CEF3FF;
    align-items: center;
}

.logo {
    margin: 12% 20%;
    font-size: 2.5rem;
    font-family:  Calibri, 'Trebuchet MS', sans-serif;
    font-style: normal;
    color: #0884FF;
    cursor: pointer;

}

img.dashboard {
    position: absolute;
    width: 8vw;
    height: 3.5vh;
    margin: 25px;
    cursor: pointer;
}
.totalContact-container {
    /* margin: -1% 1% 0 1%; */
    position: absolute;
    margin-left: 12px;
    margin-top: 5.5%;
    background: #2DA5FC;
    width: 11vw;
    height: 8vh;
    border-radius: 5px;
}
.totalContact-option{
    position: absolute;
    margin: 12px;
    width: 18vh;
    cursor: pointer;

}
.vertical{
    border-left: 3px solid white;
    height: 4vh;
    float: right;
    margin-right: 1vw;
    margin-top: 1.8vh;
}

.aside__container-options {
    height: 30%;
    margin-top: 9%;
}

.aside-line {
    position: absolute;
    width: 14.6vw;
    height: 0px;
    left: 0px;
    top: 85%;
    border: 2px solid #EAEAEA;
}

.logout-container {
    display: inline-block;
    position: absolute;
    width: 15rem;
    left: 3%;
    top: 90%;
    bottom: 12.5%;
    color: #000000;
    justify-content: space-between;
    cursor: pointer;
}
.logout-container:hover{
    color: #0884FF;
    font-size: large;
  
}



/* delete.jsx styles */

.import{
    width: 16rem;
    height: 12rem;
    background-color: #CEF3FF;
    position: absolute;
    top: 40% ;
    left: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
}

/* .fileuploader{
    height: 12rem;
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;

} */

.popdiv {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}


.ok {
    color: #1f2633;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Titillium Web";
    font-style: normal;
    font-size: 1.2rem;
}

.import img{
    width: 13rem;
}

.cancel {
    width: 100px;
    height: 30px;
    background: #2da5fc;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

/* label.sc-bczRLJ.kiHXWb {
    height: 84%;
    min-width: 89%;
    max-width: 100%;
   
} */

label.sc-gsnTZi.loHApx {
        position: absolute;
        width: 13rem;
        height: 10em;
        background-color: #CEF3FF;
        padding: 8px 16px 8px 8px;
        position: absolute;
        /* top: 1vh;
        left: 30%; */
        flex-grow: 0;
        min-width: 209px;
        max-width: 508px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 10px;
}
